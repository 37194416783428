import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import InputMask from 'react-input-mask';

import Collapsible from 'react-collapsible';

import { loadCardDetails, sendNotification, insertTempProspector } from '../store/actions/cardActions'

const ref = React.createRef();

class GuestForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			cardID: this.props.match.params.cardID,
			page: 1,
			password: '',
			error: {},
			highlighVideo: null,
			highlightPreview: null,
			allowForm: true,
		}
	}

	componentDidMount() { }

	_changeHandler = event => {
		console.log(event.target.name);
		console.log(event.target.value);
		this.setState({
			[event.target.name]: event.target.value
		})
	}
	_handleSubmit = event => {
		event.preventDefault()

		let { firstName, lastName, email, phone, cardID } = this.state
		var guestUser = { firstName, lastName, email, phone }

		// set data to local store
		localStorage.setItem('guestUser', JSON.stringify(guestUser))

		// send notification //
		// this.props.sendNotification({
		// 	"card": cardID,
		// 	"type": "card",
		// 	"type_id": cardID,
		// })

		this.props.insertTempProspector({ cardID, firstName, lastName, email, phone })

	}
	_disableForm(willClose) {
		console.log('need update');
		if (willClose) {
			this.setState({ allowForm: false })
		}
	}

	render() {
		let { cardState, pageData, auth, tabInfo, prosState } = this.props
		let { firstName, lastName, email, phone, allowForm } = this.state

		try {
			allowForm = prosState.status == 'ready' ? false : true;
		} catch (error) {

		}
		let com_tem_title;
		try {
			com_tem_title = cardState.selectedCard.card_meta.com_tem_title;
		} catch (error) {

		}

		console.log('com_tem_title');
		console.log(com_tem_title);

		return (
			<div>
				{(() => {
					if (allowForm) {
						return (
							<div className="guest-form">
								<div className="area-form">
									<form onSubmit={this._handleSubmit}>
										<div className="custom-title">{com_tem_title}</div>
										<div className="form-header">
											<div className="header-title">Enter your information</div>
											<div className="header-submit">
												<button type="submit">Submit</button>
											</div>
										</div>
										<div className="form-fields">
											<div className="single-filed">
												<label htmlFor="guest_firstName" className="filed-label">First name</label>
												<input type="text" id="guest_firstName" name="firstName" className="filed-input"
													autoFocus={true}
													value={firstName}
													onChange={this._changeHandler} required />
											</div>
											<div className="single-filed">
												<label htmlFor="guest_lastName" className="filed-label">Last name</label>
												<input type="text" id="guest_lastName" name="lastName" className="filed-input"
													value={lastName}
													onChange={this._changeHandler} required />
											</div>
											<div className="single-filed">
												<label htmlFor="guest_email" className="filed-label">Email</label>
												<input type="email" id="guest_email" name="email" className="filed-input"
													value={email}
													onChange={this._changeHandler} required />
											</div>
											<div className="single-filed">
												<label htmlFor="guest_Phone" className="filed-label">Phone</label>
												{/* <span className="filed-label">Phone</span> */}
												<InputMask
													className="filed-input"
													name="phone"
													id="guest_Phone"
													onChange={this._changeHandler}
													value={phone}
													type="tel"
													required
													mask="(999) 999-9999" maskChar={null} />

												{/* <input type="tel" name="phone" className="filed-input"
													value={phone}
													onChange={this._changeHandler} required /> */}
											</div>
										</div>
									</form>
								</div>
							</div>)
					}
				})()}
			</div>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	cardState: state.card,
	prosState: state.prospector,
})
const mapDispatchToProps = ({
	sendNotification,
	insertTempProspector
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuestForm))
