import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Collapsible from 'react-collapsible';

import { loadCardDetails, sendNotification } from '../../store/actions/cardActions'

var QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;
const ReactQuill = require('react-quill'); // CommonJS


const ref = React.createRef();

class TabTypeSMM extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cardID: this.props.match.params.cardID,
			error: {},
		}
	}

	componentDidMount() {
		// Send can open notification //
	}
	render() {
		let { socialMedia } = this.props

		let facebook, twitter, instagram, youtube, snapchat, tiktok;
		let smmEnabled = false;

		try { facebook = socialMedia.facebook.sm_status != 0 ? socialMedia.facebook.sm_link : null; } catch (error) { }
		try { twitter = socialMedia.twitter.sm_status != 0 ? socialMedia.twitter.sm_link : null; } catch (error) { }
		try { instagram = socialMedia.instagram.sm_status != 0 ? socialMedia.instagram.sm_link : null; } catch (error) { }
		try { youtube = socialMedia.youtube.sm_status != 0 ? socialMedia.youtube.sm_link : null; } catch (error) { }
		try { snapchat = socialMedia.snapchat.sm_status != 0 ? socialMedia.snapchat.sm_link : null; } catch (error) { }
		try { tiktok = socialMedia.tiktok.sm_status != 0 ? socialMedia.tiktok.sm_link : null; } catch (error) { }

		if (facebook || twitter || instagram || youtube || snapchat || tiktok) {
			smmEnabled = true;
		}

		console.log('socialMedia', socialMedia)


		return (
			smmEnabled && <div className="single-tab type-SMM">
				<div className="all-links">
					{facebook && <a className="smm-icon" href={facebook} target="_blank" rel="noopener noreferrer"> <i class="fab fa-facebook-f"></i> </a>}
					{twitter && <a className="smm-icon" href={twitter} target="_blank" rel="noopener noreferrer"> <i class="fab fa-twitter"></i> </a>}
					{instagram && <a className="smm-icon" href={instagram} target="_blank" rel="noopener noreferrer"> <i class="fab fa-instagram"></i> </a>}
					{youtube && <a className="smm-icon" href={youtube} target="_blank" rel="noopener noreferrer"> <i class="fab fa-youtube"></i> </a>}
					{snapchat && <a className="smm-icon" href={snapchat} target="_blank" rel="noopener noreferrer"> <i class="fab fa-snapchat"></i> </a>}
					{tiktok && <a className="smm-icon" href={tiktok} target="_blank" rel="noopener noreferrer"> <i class="fab fa-tiktok"></i> </a>}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
})
const mapDispatchToProps = ({
	sendNotification,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabTypeSMM))
