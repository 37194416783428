import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Collapsible from 'react-collapsible';

import { loadCardDetails, sendNotification } from '../../store/actions/cardActions'

const ref = React.createRef();

class TabType2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			cardID: this.props.match.params.cardID,
			page: 1,
			password: '',
			error: {},
			selectedHighlight: null,
			playHighlightVideo: null,
			highlighVideo: null,
			highlightPreview: null,
			isOpen: false
		}
	}

	componentDidMount() {
		// Send can open notification //
		let { cardID } = this.state

		// set first video as selected //
		let { tabInfo } = this.props
		try {
			this.setState({
				selectedHighlight: tabInfo.contents[0]
			})
		} catch (error) {

		}

	}
	render() {
		let { cardState, pageData, auth, tabInfo } = this.props
		let { page } = this.state
		let { user: authUser } = auth

		let { cardID, selectedHighlight, playHighlightVideo, isOpen } = this.state
		let { selectedCard } = cardState
		let { contents: tabContents } = tabInfo


		return (
			<div className="single-tab type-2" id={`tab_id_${tabInfo.TabID}`}>

				<div className={`collapsible ${isOpen == true && 'is-open'}`}>
					<div className="collapsible-header"
						onClick={() => {
							if (isOpen) {
								this.setState({ isOpen: false })
							} else {
								this.setState({ isOpen: true })

								this.goToViolation(`tab_id_${tabInfo.TabID}`)

								this.props.sendNotification(
									{
										"card": tabInfo.CardID,
										"type": "tab",
										"type_id": tabInfo.TabID,
									}
								)

							}
						}}
					>{tabInfo.tab_title}</div>

					<div className="collapsible-content">
						<div className="content-inner">

							<div className="list-videos">
								<div className="video-preview">
									{(() => {
										if (selectedHighlight != null) {
											if (playHighlightVideo != null && selectedHighlight.content.video) {
												return (
													<video controls key={selectedHighlight.ID} autoPlay={true}>
														<source src={selectedHighlight.content.video} type="video/mp4" />
														Your browser does not support the video tag.
													</video>
												);
											} else {
												return (
													<div className="preview-photo" onClick={() => { this.setState({ playHighlightVideo: true }) }}>
														<img src={selectedHighlight.content.thumbnail} alt="" />
														{(() => {
															if (selectedHighlight.content.video) {
																return (
																	<div className="play-button">
																		<i className="fas fa-play"></i>
																	</div>
																)
															}
														})()}
													</div>
												);
											}
										}
									})()}
								</div>
								{(() => {
									if (tabContents) {
										return (
											<div className="playlist">
												{
													tabContents ? tabContents.map(contentInfo => {
														return (
															<div key={contentInfo.ID} className="item-single" onClick={() => { this.setState({ selectedHighlight: contentInfo, playHighlightVideo: true }) }}>
																<img src={contentInfo.content.thumbnail} alt="" />
																{(() => {
																	if (contentInfo.content.video) {
																		return (
																			<div className="play-button">
																				<i className="fas fa-play"></i>
																			</div>
																		)
																	}
																})()}
															</div>
														)
													}) : ''
												}
											</div>
										)
									}
								})()}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}


	goToViolation = (id) => {
		setTimeout(function () {

			let violation = document.getElementById(id);
			let to = violation.offsetTop - 100;
			var smoothScrollFeature = 'scrollBehavior' in document.documentElement.style;
			var articles = document.querySelectorAll("ul#content > li"), i;
			if (to == 'elem') to = articles[id].offsetTop;
			var i = parseInt(window.pageYOffset);
			if (i != to) {
				if (!smoothScrollFeature) {
					to = parseInt(to);
					if (i < to) {
						var int = setInterval(function () {
							if (i > (to - 20)) i += 3;
							else if (i > (to - 40)) i += 9;
							else if (i > (to - 80)) i += 12;
							else if (i > (to - 160)) i += 18;
							else if (i > (to - 200)) i += 24;
							else if (i > (to - 300)) i += 40;
							else i += 60;
							window.scroll(0, i);
							if (i >= to) clearInterval(int);
						}, 50);
					}
					else {
						var int = setInterval(function () {
							if (i < (to + 20)) i -= 3;
							else if (i < (to + 40)) i -= 9;
							else if (i < (to + 80)) i -= 12;
							else if (i < (to + 160)) i -= 18;
							else if (i < (to + 200)) i -= 24;
							else if (i < (to + 300)) i -= 40;
							else i -= 60;
							window.scroll(0, i);
							if (i <= to) clearInterval(int);
						}, 50);
					}
				}
				else {
					window.scroll({
						top: to,
						behavior: 'smooth'
					});
				}
			}
		}.bind(this), 300)
	};
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	cardState: state.card,
})
const mapDispatchToProps = ({
	sendNotification,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabType2))
