import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
// import ScrollIntoView from 'react-scroll-into-view'

import Collapsible from 'react-collapsible';

import { sendNotification, setCardOpenTab } from '../../store/actions/cardActions'
import ContentType1 from './contentType1';
import ContentType2 from './contentType2';
import ContentType3 from './contentType3';

const ref = React.createRef();

class TabType3 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			cardID: this.props.match.params.cardID,
			page: 1,
			password: '',
			error: {},
			highlighVideo: null,
			highlightPreview: null,
			isOpening: false,
			isOpen: false
		}
	}

	componentDidMount() {
		// Send can open notification //
		let { cardID } = this.state

	}
	render() {
		let { cardState, pageData, auth, tabInfo, onChangeTabOpen, currentOpenTab } = this.props
		let { page } = this.state
		let { user: authUser } = auth

		let { cardID, highlighVideo, highlightPreview, isOpening, isOpen } = this.state
		let { selectedCard } = cardState

		let { openTabID } = cardState;

		let { contents: tabContents } = tabInfo

		return (
			<div className="single-tab type-1" id={`tab_id_${tabInfo.TabID}`}>

				{/* <div className={`collapsible ${tabInfo.TabID === openTabID && 'is-open'}`}> */}
				<div className={`collapsible ${isOpen == true && 'is-open'}`}>
					<div className="collapsible-header"
						onClick={() => {
							if (isOpen) {
								this.setState({ isOpen: false })
							} else {
								this.setState({ isOpen: true })

								this.goToViolation(`tab_id_${tabInfo.TabID}`)

								this.props.sendNotification(
									{
										"card": tabInfo.CardID,
										"type": "tab",
										"type_id": tabInfo.TabID,
									}
								)

							}
						}}
					>{tabInfo.tab_title}</div>
					<div className="collapsible-content">
						<div className="content-inner">
							{
								tabInfo.contents && tabInfo.contents.map(contentInfo => {

									if (contentInfo.type === "1") {
										return <ContentType1 key={`tab_content_1_${contentInfo.TabID}`} contentInfo={contentInfo} />
									} else if (contentInfo.type === "2") {
										return <ContentType2 key={`tab_content_2_${contentInfo.TabID}`} contentInfo={contentInfo} />
									} else if (contentInfo.type === "3") {
										return <ContentType3 key={`tab_content_3_${contentInfo.TabID}`} contentInfo={contentInfo} />
									}
								})
							}
						</div>
					</div>
				</div>
			</div>

		)
	}

	goToViolation = (id) => {
		setTimeout(function () {

			let violation = document.getElementById(id);
			let to = violation.offsetTop - 100;
			var smoothScrollFeature = 'scrollBehavior' in document.documentElement.style;
			var articles = document.querySelectorAll("ul#content > li"), i;
			if (to == 'elem') to = articles[id].offsetTop;
			var i = parseInt(window.pageYOffset);
			if (i != to) {
				if (!smoothScrollFeature) {
					to = parseInt(to);
					if (i < to) {
						var int = setInterval(function () {
							if (i > (to - 20)) i += 3;
							else if (i > (to - 40)) i += 9;
							else if (i > (to - 80)) i += 12;
							else if (i > (to - 160)) i += 18;
							else if (i > (to - 200)) i += 24;
							else if (i > (to - 300)) i += 40;
							else i += 60;
							window.scroll(0, i);
							if (i >= to) clearInterval(int);
						}, 50);
					}
					else {
						var int = setInterval(function () {
							if (i < (to + 20)) i -= 3;
							else if (i < (to + 40)) i -= 9;
							else if (i < (to + 80)) i -= 12;
							else if (i < (to + 160)) i -= 18;
							else if (i < (to + 200)) i -= 24;
							else if (i < (to + 300)) i -= 40;
							else i -= 60;
							window.scroll(0, i);
							if (i <= to) clearInterval(int);
						}, 50);
					}
				}
				else {
					window.scroll({
						top: to,
						behavior: 'smooth'
					});
				}
			}
		}.bind(this), 300)
	};
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	cardState: state.card,
})
const mapDispatchToProps = ({
	sendNotification,
	setCardOpenTab,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabType3))
