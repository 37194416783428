import axios from 'axios'
import * as Types from './types'

export const loadCardDetails = (data) => dispatch => {

	// Set exists minified info first //
	axios.post('card/details/', data)
		.then((res) => {
			let responseData = res.data

			dispatch({
				type: Types.CARD_DETAILS,
				payload: { cardDetails: responseData.data }
			})
		})
		.catch(error => {

		})
}

export const sendNotification = (data) => dispatch => {
	// Set exists minified info first //
	let tempProspectorID
	try {
		let tempProspector = JSON.parse(localStorage.getItem('tempProspector'))
		if (!tempProspector) {
			return;
		} else {
			tempProspectorID = tempProspector.ID
		}
	} catch (error) {

	}

	data["tempProspector"] = tempProspectorID

	axios.post('/essential/notification/card', data)
		.then((res) => {

		})
		.catch(error => {

		})
}

export const insertTempProspector = (data) => dispatch => {
	axios.post('/prospector/temp/insert', data)
		.then((res) => {
			try {
				var tempProspector = res.data.data;
				if (tempProspector) {
					localStorage.setItem('tempProspector', JSON.stringify(tempProspector))

					dispatch({
						type: Types.PROS_DETAILS,
						payload: { tempProspector }
					})
				}
			} catch (error) {

			}
		})
		.catch(error => {

		})

}


export const setCardOpenTab = (openTabID) => async dispatch => {
	dispatch({
		type: Types.CARD_OPEN_TAB,
		openTabID: openTabID
	})
}